<template>
    <v-modal modal-class="acknowledgement" @close="closeAcknowledgement(false)"
        v-model="model"
        title="Acknowledgement">
        <p v-if="policyAction === 'abandon'">
            Reassignments to be cancelled:
        </p>
        <p v-if="policyAction === 'assign'">
            {{ policies.length === 1 ? 'This policy' : 'These policies' }}
                will be reassigned:
        </p>
        <div class="policy-info">
            <span v-for="pol in policies" :key="pol.id">{{pol.policyNumber}}</span>
        </div>
        <ul v-if="policyAction === 'assign'" class="from-to-date">
            <li v-if="srcAgent.stateAgentCode">
                <span class="label">From:</span>
                <span class="code">{{agentCode(srcAgent)}}</span>
                <span class="name">{{nameOf(srcAgent)}}</span>
            </li>
            <li>
                <span class="label">To:</span>
                <span class="code">{{agentCode(toAgent)}}</span>
                <span class="name">{{nameOf(toAgent)}}</span>
            </li>
            <li>
                <span class="label">On:</span>
                <span class="code">{{effectiveDate | asLongDate}}</span>
            </li>
        </ul>
        <div class="action-buttons">
            <button class="btn cancel" @click="closeAcknowledgement(false)">Cancel</button>
            <button class="btn std" @click="closeAcknowledgement(true)">Submit</button>
        </div>
    </v-modal>
</template>

<script>
export default {
    name: 'acknowledgement-modal',
    props: {
        effectiveDate: {
            type: String,
        },
        model: {
            type: Boolean,
            required: true,
        },
        policyAction: {
            type: String,
            default: 'assign',
        },
        policies: {
            type: Array,
            required: true,
        },
        sourceAgent: {
            type: Object,
        },
        targetAgent: {
            type: Object,
            required: true,
        },
    },
    mounted: function() {
        const vue = this;
        vue.refresh();
    },
    data: function() {
        return {
            srcAgent: {},
            toAgent: {},
        }
    },
    computed: {
    },
    methods: {
        nameOf: function(agt) {
            const vue = this,
                name = vue.agentName(agt) || '';
            return name ? `(${name})` : name;
        },
        refresh: function() {
            const vue = this;
            vue.srcAgent = vue.sourceAgent || {name:''};
            vue.toAgent = vue.targetAgent || {name:''};
        },
        closeAcknowledgement: function(val) {
            const vue = this;
            vue.$emit('closeacknowledgement',val);
        },
    },
    watch: {
        sourceAgent: function() {
            const vue = this;
            vue.refresh();
        },
        targetAgent: function() {
            const vue = this;
            vue.refresh();
        },
    }

}
</script>
