<template>
    <transition name="fade">
        <section class="confirmation-panel">
            <h1>Request successfully submitted {{ (new Date()) | asLongDateTime}}</h1>
            <p v-if="policyAction === 'abandon'">
                Policy reassignments will be cancelled.
            </p>
            <p v-else>
                Policies will be reassigned
                <span v-if="previousStateAgentCode">from State Agent Code <strong>{{previousStateAgentCode}}</strong></span>
                <span v-if="newStateAgentCode"> to <strong>{{newStateAgentCode}}</strong>, </span>
                <span v-if="effectiveDate">effective <strong>{{effectiveDate | asLongDate}}</strong></span>
            </p>
            <ul class="action-policies-list" v-if="policies && policies.length > 0">
                <li v-for="pol in policies" :key="pol.policyId">{{pol.policyNumber}}</li>
            </ul>
            <div class="action-buttons">
                <button class="btn std" @click="returnToPage">Return to {{returnTo}}</button>
            </div>
        </section>
    </transition>
</template>

<script>

export default {
    name: 'confirmation-panel',
    props: {
        effectiveDate: {
            type: [String,Object],
            required: true,
        },
        newStateAgentCode: {
            type: String,
        },
        policyAction: {
            type: String,
            required: true,
        },
        policies: {
            type: Array,
        },
        previousStateAgentCode: {
            type: String,
        },
        returnTo: {
            type: String,
            default: 'Search',
        },

    },
    methods: {
        returnToPage: function() {
            const vue = this;
            vue.$emit('closeconfirmation');
        },
    }
}
</script>

<style lang="scss">
.confirmation-panel {
    text-align: center;
}
.action-policies-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
    list-style-type: none;
    margin: 1em 20%;
}
</style>
