<template>
    <div class="agent-selector">
        <label v-html="label"></label>
        <v-select class="agent-search"
            :filter="selectAgentFilter"
            @input="setAgent"
            label="text"
            :options="agentsAvailable"
            v-model="agent"
            :value="agent.text">
            <template #no-options>
                No agents available
            </template>
        </v-select>
        <div class="radius-selection" v-if="isPoint(distanceFrom)">
            Within:<br/>&nbsp;
            <label><input type="radio" v-model.number="radius" class="radius-25" value="25" name="radius"> 25 miles</label>
            <label><input type="radio" v-model.number="radius" class="radius-50" value="50" name="radius"> 50 miles</label>
            <label><input type="radio" v-model.number="radius" class="radius--1" value="-1" name="radius"> Statewide</label>
        </div>
    </div>
</template>

<script>

/*
 * Haversine formula distance between two lat/lng coordinates
 */
const R = 6372.8 / 1.609344, // miles
    toRad = deg => deg/180.0 * Math.PI;

function haversineDistance(from,to) {
    const [lat1,lon1,lat2,lon2] = [from.lat,from.lon,to.lat,to.lon].map(toRad),
        [dLat,dLon] = [lat2 - lat1,lon2 - lon1],
        a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) *
            Math.cos(lat1) * Math.cos(lat2),
        c = 2 * Math.asin(Math.sqrt(a));
    return R * c;
}

export default {
    name: 'select-agent',
    props: {
        activeOnly: {
            type: Boolean,
            default: true,
        },
        agents: {
            type: Array,
            required: true,
        },
        distanceFrom: {
            type: Object,
            required: false,
        },
        label: {
            type: String,
            default: 'Agent:',
        },
        currentAgent: {
            type: Object,
            required: false,
        }
    },
    mounted: function() {
        const vue = this;
        vue.agent = vue.currentAgent || {};
    },
    data: function() {
        return {
            agent: {},
            radius: -1,
        };
    },
    computed: {
        agentsAvailable: function() {
            const vue = this,
                activeOnly = vue.activeOnly,
                agents = vue.agents.filter(agt => {
                    return activeOnly ? agt.isActive : agt;
                }),
                isPoint = vue.isPoint,
                from = vue.distanceFrom,
                radius = vue.radius;

            return (radius < 0 || !isPoint(from)) ? agents :
                agents.filter(agt => (agt.stateAgentCode || '').slice(-4) === '0000' ||
                    (isPoint(agt.location) ? haversineDistance(from,agt.location) <= radius : false));
        },
    },
    methods: {
        isPoint: function(point) {
            // IE doesn't support Number.isNumber:
            const isNumber = n => typeof n === 'number' && isFinite(n);
            return Boolean(point && isNumber(point.lon) && isNumber(point.lat));
        },
        selectAgentFilter: function(opts,searchStr) {
            const search = searchStr.replace(/^(..?)(-)(.*)/,(m,p,dash,s) => `${p}${s}`).toLowerCase();

            return search.length > 0 ?
                opts.filter(agt => Boolean(
                    (agt.stateAgentCode || '').replace(/-/g,'').slice(0,search.length).toLowerCase() === search ||
                    (agt.name || '').toLowerCase().match(search))) :
                opts;
        },
        setAgent: function(agent) {
            const vue = this;
            vue.$emit('setagent',agent);
        }
    },
}
</script>

<style lang="scss">
.agent-selector {
    width: 20em;
}
.radius-selection label {
    padding-right: 0.5em;
}
</style>
