<template>
    <div class="history-filters">
        <label class="multi-search">
            <div class="label">Filter:</div>
            <div>
                <span v-if="filterByUsers" class="by-user">
                    <v-select class="value user"
                        label="text"
                        :options="usersList"
                        @input="setQueryFromUser">
                    </v-select>
                </span>
                <span v-else class="by-policy">
                    <input class="value search" v-model.trim.lazy="input" @change="setQueryFromInput">
                    <span class="clear-input" @click="clearSearch" title="Clear filter"> &cross;</span>
                </span>
            </div>
        </label>
        <label>
            <input type="radio" name="searchtype" class="value" v-model="filter.type" value="policyNumber">
            <span class="label">Policy&nbsp;Number</span>
        </label>
        <label>
            <input type="radio" name="searchtype" class="value" v-model="filter.type" value="previousStateAgentCode">
            <span class="label">Agent</span>
        </label>
        <label>
            <input type="radio" name="searchtype" class="value" v-model="filter.type" value="newStateAgentCode">
            <span class="label">New&nbsp;Agent</span>
        </label>
        <label>
            <input type="radio" name="searchtype" class="value" v-model="filter.type" value="userId">
            <span class="label">Requester</span>
        </label>
        <label>
            <button class="btn clear" @click="clearAll" title="Clear all filters">&cross;&nbsp;Clear</button>
        </label>
    </div>
</template>

<script>

export default {
    name: 'history-filters',
    props: {
        usersList: {
            type: Array,
            required: true,
        },
    },
    data: function() {
        return {
            input: '',
            filter: {
                query: '',
                type: '',
                userId: '',
            }
        };
    },
    computed: {
        filterByUsers: function() {
            const vue = this;
            return vue.filter.type === 'userId';
        },
        filterByAgent: function() {
            const vue = this;
            return vue.filter.type in {previousStateAgentCode:1, newStateAgentCode:1};
        },
        filterByPolicy: function() {
            const vue = this;
            return vue.filter.type === 'policyNumber';
        },
        noSelection: function() {
            const vue = this;
            return vue.filter.type === '';
        },
    },
    methods: {
        isGuid: function(val) {
            //  e.g.: '3f40cbf9-0b8a-4b65-9562-b8624ace6797'
            return (/^[0-9a-f]{8}((-[0-9a-f]{4}){3})-[0-9a-f]{12}$/i).test(val.trim());

        },
        fullName: function(user) {
            return `${user.firstName} ${user.lastName}`.trim();
        },
        setQueryFromInput: function(evt) {
            const vue = this;
            vue.filter.query = evt.target.value;
        },
        setQueryFromUser: function(user) {
            const vue = this;
            vue.filter.userId = user.id;
        },
        clearSearch: function() {
            const vue = this;
            vue.input =
                vue.filter.query = ''
                vue.filter.userId = '';
        },
        clearAll: function() {
            const vue = this;
            vue.input =
                vue.filter.query =
                vue.filter.userId =
                vue.filter.type = '';
            vue.$emit('search',vue.filter);
        }
    },
    watch: {
        input: function() {
            const vue = this;
            vue.filter.query = vue.input;
        },
        filter: {
            deep: true,
            handler: function() {
                const vue = this,
                    type = vue.filter.type,
                    query = type === 'userId' ? vue.filter.userId : vue.filter.query,
                    isGuid = vue.isGuid(query);

                switch(type) {
                    case 'userId':
                        if (isGuid) {
                            vue.$emit('search',{query, type});
                        }
                        break;
                    case '':
                        if (!query) {
                            vue.$emit('search',{query:'', type:''});
                        }
                        break;
                    default:
                        if (query) {
                            vue.$emit('search',{query: query, type: type});
                        }
                }
            }
        }
    }

}
</script>

<style lang="scss">
.history-filters {
    display: flex;
    gap: 1em;
    justify-content: center;
    margin: 0 auto;
    padding: 0 4em;
    .label {
        height: 2em;
        line-height: 2em;
        padding: 0 0.2em;
    }
    .multi-search {
        width: 20em;
        .label {
            margin-top: -2em;
        }
        .search {
            border: 1px solid #ddd;
            border-radius: 4px;
            height: 2.2em;
            padding-right: 1em;
            width: 22.8em;
        }
        .clear-input {
            margin-left: -1.2em;
            padding-left: 0.2em;
        }
        .by-policy {
            position: relative;
            .clear-input {
                position: absolute;
                right: 0.25em;
                top: -0.2em;
            }
        }
    }
}
</style>
