<template>
    <transition name="fade">
        <section class="reassignment-history">
            <confirmation-panel v-if="actionIsComplete"
                :effectiveDate="fullEffectiveDate"
                :newStateAgentCode="targetAgent.stateAgentCode"
                :policyAction="policyAction"
                :policies="actionHistories"
                returnTo="History"
                @closeconfirmation="resetChanges">
            >
            </confirmation-panel>
            <div v-if="!actionIsComplete">
                <h1> Reassignment History </h1>
                <history-filter
                    :usersList="usersList"
                    @search="searchHistories">
                </history-filter>
                <h3 class="filter-description" v-if="histories.length > 0" v-html="filterDescription">
                </h3>
                <div class="histories-list">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th class="created-on sortable"
                                    :class="sortClass('createdOn')"
                                    @click="sortBy('createdOn')">Date</th>
                                <th class="previous-agent sortable"
                                    :class="sortClass('previousStateAgentCode')"
                                    @click="sortBy('previousStateAgentCode')">Agent</th>
                                <th class="new-agent sortable"
                                    :class="sortClass('newStateAgentCode')"
                                    @click="sortBy('newStateAgentCode')">New&nbsp;Agent</th>
                                <th class="policy-number">Policy</th>
                                <th class="pet-names">Pets</th>
                                <th class="effective-date sortable"
                                    :class="sortClass('effectiveDate')"
                                    @click="sortBy('effectiveDate')">Effective&nbsp;On</th>
                                <th class="status sortable"
                                    :class="sortClass('status')"
                                    @click="sortBy('status')">Status</th>
                                <th class="requester">Requester</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!loading && histories.length === 0">
                                <td colspan="5" class="no-data">No reassignments</td>
                            </tr>
                            <tr v-for="(row) in histories" :key="rowKey(row)"
                                @click="selectRow(row)"
                                :class="{'is-assigned': row.isSelected, 'is-complete': isReassignmentComplete(row)}">
                                <td class="col-select">
                                    <input type="checkbox" :disabled="isReassignmentComplete(row)" :value="row.isSelected" v-model="row.isSelected">
                                </td>
                                <td class="created-on">{{row.createdOn | asDate}}</td>
                                <td class="previous-agent">{{row.previousStateAgentCode}}</td>
                                <td class="new-agent">{{row.newStateAgentCode}}</td>
                                <td class="policy-number">{{row.policyNumber}}</td>
                                <td class="pet-names">{{row.petNames}}</td>
                                <td class="effective-date">{{row.effectiveDate | asDate}}</td>
                                <td class="status">{{row.status}}</td>
                                <td class="requester" :title="row.requesterId">{{requesterById(row.requesterId)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <v-pagination
                    v-if="!loading && histories.length > 0"
                    v-model="pager.pageIndex"
                    :page-count="pager.totalPages"
                    :class="{hide: pager.totalPages < 1}"
                    :labels="PAGINATION.labels"
                    :classes="PAGINATION.classes"
                ></v-pagination>
                <div class="new-reassignments" v-if="selectedHistories.length > 0">
                    <div class="cancellations">
                        <button class="btn clear" @click="maybeAbandon">Cancel reassignments</button>
                    </div>
                    <div class="reassignments" v-if="policiesSelectedAreFromOneState">
                        <h3>Reassign selected policies</h3>
                        <div class="controls">
                            <agent-selector
                                label="New agent:"
                                :agents="newAgentList"
                                @setagent="setTargetAgent"
                                :value="targetAgent.text"
                            >
                            </agent-selector>
                            <span>
                                <label>Effective date:</label><br/>
                                <input v-model="effectiveDate"
                                    :max="maxDate"
                                    :min="minDate"
                                    class="effective-date"
                                    title="Policy reassignment effective date"
                                    type="date">
                            </span>
                        </div>
                        <button class="btn std" :aria-disabled="!canSubmit" @click="maybeSubmit">Submit</button>
                    </div>
                </div>
                <acknowledgement-modal
                    :model="acknowledgementIsOpen"
                    :policyAction="policyAction"
                    :policies="selectedHistories"
                    :targetAgent="targetAgent"
                    :effectiveDate="fullEffectiveDate"
                    @closeacknowledgement="closeAcknowledgement">
                </acknowledgement-modal>
            </div>
        </section>
    </transition>
</template>

<script>
import vPagination from 'vue-plain-pagination';
import {Net,Urls} from '@/utilities/Net';
import {Utils} from '@/utilities/Utils';
import historyFilter from '@/Components/historyFilter';
import agentSelector from '@/Components/agentSelector';
import acknowledgementModal from '@/Components/acknowledgementModal';
import confirmationPanel from '@/Components/confirmationPanel';

const {stateId,stateCode,PAGER} = Utils;
const HISTORY_PAGESIZE = 15;

export default {
    name: 'reassignment-history',
    components: {
        acknowledgementModal,
        confirmationPanel,
        agentSelector,
        historyFilter,
        vPagination,
    },
    data: function() {
        const result = {

            acknowledgementIsOpen: false,
            actionHistories: [],
            actionIsComplete: false,
            effectiveDate: '',
            histories: [],
            loading: false,
            policyAction: '',
            targetAgent: {},

            usersList: [],

            search: {
                query: '',
                type: '',
            },
            sort: {
                sortBy: 'createdOn',
                descending: true,
            },
            PAGINATION: PAGER,
            pager: {
                pageIndex: 1,
                pageSize: HISTORY_PAGESIZE,
                totalCount: 0,
                totalPages: 0,
            },
        };
        return result;
    },
    mounted: function() {
        const vue = this;
        vue.refresh();
    },
    computed: {
        filtered: function() {
            const vue = this,
                search = vue.search;
            return search.query && search.type;
        },
        filterDescription: function() {
            const vue = this,
                query = vue.search.query,
                type = vue.search.type,
                descr = {
                    previousStateAgentCode: 'held by',
                    newStateAgentCode: 'targetted for',
                };
            if (!vue.filtered) {
                return 'All reassignments';
            }
            switch (type) {
                case 'userId':
                    return `Reassignments requested by ${vue.requesterById(query)}`;
                case 'policyNumber':
                    return `Reassignments for policy ${query}`;
                default:
                    return `Reassignments for policies ${descr[type]} ${query} (${vue.agentName(vue.agentsList,vue.search.query)})`;
            };
        },
        canSubmit: function() {
            const vue = this;
            return Boolean(vue.policiesSelectedAreFromOneState && vue.targetAgent.stateAgentCode && vue.effectiveDate);
        },
        fullEffectiveDate: function() {
            const vue = this,
                today = new Date(),
                timeNow = today.toTimeString().slice(0,8),
                effDate = vue.effectiveDate || today.toISOString().slice(0,10);

            return (new Date(`${effDate}T${timeNow}`)).toISOString();
        },
        policiesSelectedAreFromOneState: function() {
            const vue = this,
                selected = vue.selectedHistories,
                srcAgents = selected.reduce((set,pol) => set.add(stateId(stateCode(pol.previousStateAgentCode))),new Set());

            return srcAgents.size === 1;
        },
        selectedHistories: function() {
            const vue = this;
            return vue.histories.filter(p => p.isSelected);
        },
        newAgentList: function() {
            const vue = this,
                selected = vue.selectedHistories,
                policySt = pol => stateId(stateCode(pol.previousStateAgentCode)),
                agtSt = agt => stateId(stateCode(agt.stateAgentCode)),
                agentState = (Array.from(selected.reduce((set,pol) => set.add(policySt(pol)),new Set())))[0];

            return vue.agentsList.filter(agt => agtSt(agt) === agentState);
        },
    },
    methods: {
        isReassignmentComplete: function(pol) {
            return pol.status === 'Complete' || pol.status === 'Cancelled';
        },
        requesterById: function(id) {
            const vue = this,
                user = vue.usersList.find(u => u.id === id);
            return user ? `${user.firstName} ${user.lastName}`.trim() : '';
        },
        searchHistories: function(search) {
            const vue = this;
            vue.pager.pageIndex = 1;
            vue.search = search;
            vue.refresh();
        },
        submitChanges: function(changes) {
            const vue = this,
                histories = changes,
                action = vue.policyAction,
                result = action === 'assign' ?
                    Net.post(Urls.reassignPolicy(),{
                        ReassignPolicyRequests: histories.map(hist => ({
                            id: hist.id,
                            effectiveDate: vue.fullEffectiveDate,
                            newAgentOfficeId: vue.targetAgent.id,
                            newStateAgentCode: vue.targetAgent.stateAgentCode,
                            policyNumber: hist.policyNumber,
                            previousAgentOfficeId: hist.previousAgentOfficeId,
                            previousStateAgentCode: hist.previousStateAgentCode,
                            status: 'Pending',
                        })),
                    }) :
                    Net.put(Urls.cancelRequests(),{
                        pendingReassignPolicyRequestIds: histories.map(hist => hist.id),
                    });

            result
                .then((data) => {
                    vue.actionHistories = histories.slice();
                    vue.actionIsComplete = true;
                })
                .catch((err) => vue.networkError(`update request failed: ${err.message}`));
            return result;
        },
        closeAcknowledgement: function(andSubmit) {
            const vue = this;
            vue.acknowledgementIsOpen = false;
            if (andSubmit) {
                vue.submitChanges(vue.selectedHistories)
                    .finally(function(data) {
                        vue.refresh();
                    });
            }
        },
        maybeAbandon: function() {
            const vue = this,
                histories = vue.selectedHistories;
            if (histories.length > 0) {
                vue.policyAction = 'abandon';
                vue.acknowledgementIsOpen = true;
            }
        },
        maybeSubmit: function() {
            const vue = this,
                histories = vue.selectedHistories,
                newAgent = vue.targetAgent,
                newEffectiveDate = vue.effectiveDate;
            if (histories.length > 0 &&
                newAgent.stateAgentCode && newEffectiveDate) {
                vue.policyAction = 'assign';
                vue.acknowledgementIsOpen = true;
            }
        },
        rowKey: function(obj) {
            return obj.policyNumber+obj.id;
        },
        sortClass: function(fieldName) {
            const vue = this;
            return (vue.sortingBy(fieldName) ? ' sorted' : '') +
                (vue.sort.descending ? '' : ' sort-reverse');
        },
        selectRow: function(row) {
            row.isSelected = (row.status === 'Pending') ? !row.isSelected : false;
        },
        setTargetAgent: function(agent) {
            const vue = this;
            vue.targetAgent = agent;
        },
        sortBy: function(fieldName) {
            const vue = this;
            vue.sort.descending = (fieldName !== vue.sort.sortBy) ? true :
                !vue.sort.descending;

            vue.sort.sortBy = fieldName;
            if (vue.pager.pageIndex === 1) {
                vue.refresh();
            }
            vue.pager.pageIndex = 1;
        },
        sortingBy: function(fieldName) {
            const vue = this;
            return vue.sort.sortBy === fieldName;
        },
        resetChanges: function() {
            const vue = this;
            vue.acknowledgementisOpen =
                vue.actionIsComplete = false;
        },

        getUsers: function() {
            const vue = this;
            let result;

            if (sessionStorage.sfUsers) {
                const usersList = JSON.parse(sessionStorage.sfUsers);
                vue.usersList = usersList;
                result = Promise.resolve(true);
            } else {
                result = Net.get(Urls.getUsers());
                result.then(data => {
                    const fullName = (u) => `${u.firstName} ${u.lastName}`.trim(),
                        list = (data?.data) || [];
                    list.sort((a,b) => {
                        const aname = fullName(a),
                            bname = fullName(b);
                        return aname === bname ? 0 :
                            aname < bname ? -1 : 1;
                    });
                    list.forEach(u => u.text = `${u.firstName} ${u.lastName}`);
                    sessionStorage.sfUsers = JSON.stringify(list);
                    vue.usersList = list;
                })
                    .catch(err => vue.networkError(`user list retrieval failed: ${err.message}`));
            }
            return result;
        },
        refresh: function() {
            const vue = this;

            function refreshHistories() {
                const search = vue.search,
                    params = () => {
                        const sort = vue.sort,
                            param = Object.assign({},vue.pager);
                        if (search.query) {
                            param[search.type] = search.query;
                        }
                        if (sort.sortBy) {
                            param.sortBy = [sort.sortBy];
                            param.sortDescending = sort.descending;
                        }
                        param.pageIndex = Math.max(param.pageIndex,1);
                        delete param.totalCount;
                        delete param.totalPages;
                        return param;
                    };

                const result = Net.post(Urls.getHistory(),params())
                    .then(data => {
                        vue.histories = (data?.data?.items || [])
                            .map(h => ({...h,...{isSelected: false}}));
                        Object.keys(vue.pager)
                            .filter(k => k !== 'pageSize')
                            .forEach(key => vue.pager[key] = data.data[key]);
                    })
                    .catch(err => vue.networkError(`history list retrieval failed: ${err.message}`));
                return result;
            }

            vue.loading = true;
            vue.getUsers()
                .then(refreshHistories)
                .finally(() => vue.loading = false);
        },
    },
    watch: {
        'pager.pageIndex': function() {
            const vue = this;
            vue.refresh();
        },
    }
}
</script>

<style lang="scss">
@import "../styles/sfadmin";

.reassignment-history {
    h1 {
        margin: 0 0 1em;
        text-align: center;
    }
    .histories-list tbody tr:not(.is-complete) {
        cursor: pointer;
    }
    .filter-description {
        text-align: center;
    }
    tr.is-complete {
        td {
            color: #555;
        }
        .col-select input {
            visibility: hidden;
        }
    }
    .new-reassignments {
        margin: 0 4em;
        display: flex;
        justify-content: flex-start;
        gap: 2em;
        .cancellations {
            margin-top: 8em;
        }
        .controls {
            display: flex;
            gap: 2em;
            justify-content: flex-start;
            margin-bottom: 1em;
        }
    }
}
</style>
